<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
  >
    <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon>
    <v-btn
      class="hidden-sm-and-down logo"
      text
      to="/"
    >
      <img
        src="@/assets/logo LC-new CI.svg"
        alt="logo"
      />
      <div class="company-name"><span>|</span>Legal Connect</div>
    </v-btn>

    <EnvironmentText
      :text="'Testing Environment'"
      v-if="showEnvironmentText"
    ></EnvironmentText>

    <div class="flex-grow-1 blue"></div>
    <!-- <div>
      <v-switch
        v-if="$route.name === 'case-details' && !isServerProduction"
        value
        input-value="true"
        v-model="$store.state.caseinfo.useLocalStorage"
        label="Use LocalStorage + Fake api"
        class="mt-6"
        @change="$store.dispatch('caseinfo/setUseLocalStorage', $event)"
      ></v-switch>
    </div> -->

    <div style="position: fixed;top: 47px;left: 850px;">

      <!-- <span style="position: fixed;top: 47px;left: 1250px;">.</span> -->
      <span>.</span>
      <input
        v-model="internalCode"
        style="
        width:17px;
        cursor:default;
        font-size:10px
      "
        @keyup.enter="submitInternalCode()"
      >
      <span
        class="red--text"
        v-if="$store.getters['pageinfo/isSupportMode']"
        style="font-size:16px"
      ><b>..Support Mode..</b></span>
    </div>

    <!-- <span style="position: fixed;top: 47px;left: 1250px;">.</span>
    <span
      class="red--text"
      v-if="$store.getters['pageinfo/isSupportMode']"
    ><b>..Support Mode...</b></span>

    <input
      v-model="internalCode"
      style="
      width:17px;
      cursor:default;
      "
      @keyup.enter="submitInternalCode()"
    > -->
    <span style="margin-right:150px"></span>

    <v-btn
      class="hidden-sm-and-down"
      text
      to="/lawyer-calendar"
      v-if="!isServerProduction"
    >
      <v-icon>event</v-icon>
    </v-btn>

    <v-menu>
      <template v-slot:activator="{ on }">
        <v-btn
          class="ml-1"
          tag="button"
          text
          v-on="on"
        >
          สวัสดี {{displayName}} | {{currentRole}}
          <v-icon class="ml-2">mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list class="mt-10">
        <v-list-item :to="'/profile'">
          <v-list-item-title>
            <v-icon class="mr-2">mdi-account</v-icon>Profile
          </v-list-item-title>
        </v-list-item>
        <v-list-item :to="'/login'">
          <v-list-item-title>
            <v-icon class="mr-2">call_missed_outgoing</v-icon>ออกจากระบบ
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapMutations } from "vuex";
import { Server } from "../../js/constants";
import { getToken } from "./../../js/authorization";
import manageStorage from "./../../js/manage-localstorage";
import EnvironmentText from "./../custom/environment-text.vue";

export default {
  components: {
    EnvironmentText,
  },
  data: () => ({
    drawer: null,
    internalCode: null,
  }),
  mounted() {
    this.onResponsiveInverted();
    window.addEventListener("resize", this.onResponsiveInverted);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResponsiveInverted);
  },

  methods: {
    ...mapMutations("app", ["setDrawer", "toggleDrawer"]),
    onClick() {
      this.setDrawer(!this.$store.state.app.drawer);
    },
    onResponsiveInverted() {
      if (window.innerWidth < 991) {
        this.responsive = true;
      } else {
        this.responsive = false;
      }
    },
    submitInternalCode() {
      this.$store.state.pageinfo.forceInternal++;
      manageStorage.updateObject("internalCode", this.internalCode);
      this.internalCode = null;
    },
  },
  computed: {
    title() {
      return `${process.env.VUE_APP_NAME} [${process.env.VUE_APP_SERVER}]`;
    },
    showEnvironmentText() {
      return process.env.VUE_APP_SERVER != Server.Production;
    },
  },
};
</script>

<style>
/* Fix coming in v2.0.8 */
#core-app-bar {
  width: auto;
}

#core-app-bar a {
  text-decoration: none;
}
</style>
