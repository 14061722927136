<template>
  <span
    class="testing-env-text"
    :class="color"
  >{{ text }}</span>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style>
span.testing-env-text {
  opacity: 0;
  animation: blinking 3s linear infinite;
  color: red;
  font-weight: 500;
}

@keyframes blinking {
  from,
  49.9% {
    opacity: 0;
  }
  50%,
  to {
    opacity: 1;
  }
}
</style>